<template>
    <the-layout-without-login>

    </the-layout-without-login>
</template>

<script>

    import TheLayoutWithoutLogin from '../../containers/TheLayoutWithoutLogin.vue'
    import axios from 'axios';
    import appSettings from '../../util/settings';
    import store from '../../util/store.js';
    import dialog from '../../util/dialog';

    //const GoogleLogin = () => import('@/views/account/GoogleLogin')

    export default {
        components: {
            TheLayoutWithoutLogin
        },
        data() {
            return {
            }
        },
        mounted(){
            this.login("campaign20200625", "getchee");
        },
        methods: {
            loginResult(response){
                let data = response.data;
                if(data.IsLogin){
                    store.commit("setTryFree");
                    store.commit("setUser", data);
                    this.$router.push({
                        name:'MarketReport',
                        params:{
                        }
                    });
                }
                else{
                    dialog.showInforDialog(data.Message);
                        this.$router.push({
                        path: '/register',
                        params: {}
                    });
                }
            },
            login(username, password) {
                let loginResult = this.loginResult;
                let encryptPassword = CryptoJS.encrypt(password);

                axios.post(appSettings.LoginDb,
                    {
                        userId : username,
                        password : encryptPassword
                    })
                    .then(loginResult)
                    .catch(function (error) {
                        dialog.showInforDialog(error);
                        console.log(error);
                    });
            }
        },
        name: 'Login'
    }
</script>
