<template>  
<div class="c-app sign-container">
    <CContainer>  
        <div class="login-logo mt-5"></div>
        <CRow class="row justify-content-center">
            <CCol md="6" >
                <CCard class="mb-0"> 
                    <CCardBody class="p-3">
                     <slot></slot>
                    </CCardBody>
                </CCard> 
            </CCol> 
        </CRow>
        <CRow class="w-100 justify-content-center" style="margin-left:0px">
            <CCol md="6">
                <div class="text-center mt-1 "><small class=""> © 2020 getchee. All rights reserved.</small></div>
            </CCol>
        </CRow> 
    </CContainer>
</div>
</template>

<script>
export default {}
</script>
